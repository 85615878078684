import { cva } from 'class-variance-authority';
const formControl = cva('flex flex-col w-full group', {
    variants: {
        size: {
            sm: 'gap-text-field-sm-y',
            md: 'gap-text-field-md-y',
            lg: 'gap-text-field-lg-y',
            xl: 'gap-text-field-xl-y',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
const root = cva([
    'flex items-center',
    'box-border',
    'w-full',
    'bg-text-field',
    'border-text-field',
    'hover:bg-text-field-hover',
    'hover:border-text-field-hover',
    'focus-within:bg-text-field-focus',
    'focus-within:outline-none',
    'focus-within:ring-text-field-focus',
    'focus-within:ring-1',
    'focus-within:ring-inset',
    'transition-colors duration-75 ease-out',
    'autofill:!bg-text-field autofill:shadow-none',
    'text-text-field-content',
], {
    variants: {
        state: {
            default: 'focus-within:ring-text-field-focus focus-within:border-info-focus focus-within:hover:border-info-focus',
            error: 'focus-within:ring-text-field-focus-error focus-within:border-error-focus focus-within:hover:border-error-focus',
            success: 'focus-within:ring-text-field-focus-success focus-within:border-success-focus focus-within:hover:border-success-focus',
            disabled: 'pointer-events-none cursor-not-allowed border-text-field-disabled bg-text-field-disabled',
        },
        size: {
            sm: 'h-text-field-sm px-text-field-sm-x rounded-text-field-sm gap-text-field-sm-x',
            md: 'h-text-field-md px-text-field-md-x rounded-text-field-md gap-text-field-md-x',
            lg: 'h-text-field-lg px-text-field-lg-x rounded-text-field-lg gap-text-field-lg-x',
            xl: 'h-text-field-xl px-text-field-xl-x rounded-text-field-xl gap-text-field-xl-x',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
const input = cva(['bg-transparent outline-none border-none h-full w-full'], {
    variants: {
        size: {
            sm: 'placeholder:text-field-text-sm text-field-text-sm',
            md: 'placeholder:text-field-text-md text-field-text-md',
            lg: 'placeholder:text-field-text-lg text-field-text-lg',
            xl: 'placeholder:text-field-text-xl text-field-text-xl',
        },
        state: {
            default: 'placeholder:text-text-field-placeholder text-text-field-content',
            error: 'placeholder:text-text-field-placeholder text-text-field-content',
            success: 'placeholder:text-text-field-placeholder text-text-field-content',
            disabled: 'text-text-field-disabled placeholder:text-text-field-disabled',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
const icon = cva('', {
    variants: {
        state: {
            default: 'text-primary',
            error: 'text-error-primary',
            success: 'text-success-primary',
            disabled: 'text-disabled',
        },
        size: {
            sm: 'w-text-field-icon-sm h-text-field-icon-sm',
            md: 'w-text-field-icon-md h-text-field-icon-md',
            lg: 'w-text-field-icon-lg h-text-field-icon-lg',
            xl: 'w-text-field-icon-xl h-text-field-icon-xl',
        },
    },
    defaultVariants: {
        size: 'md',
        state: 'default',
    },
});
const hint = cva('', {
    variants: {
        state: {
            default: 'text-text-field-hint',
            error: 'text-text-field-hint-error',
            success: 'text-text-field-hint',
            disabled: 'text-text-field-disabled',
        },
        size: {
            sm: 'text-field-hint-sm',
            md: 'text-field-hint-md',
            lg: 'text-field-hint-lg',
            xl: 'text-field-hint-xl',
        },
    },
    defaultVariants: {
        state: 'default',
        size: 'md',
    },
});
const optional = cva('', {
    variants: {
        state: {
            default: 'text-text-field-optional',
            error: 'text-text-field-optional',
            success: 'text-text-field-optional',
            disabled: 'text-text-field-disabled',
        },
        size: {
            sm: 'text-field-hint-sm',
            md: 'text-field-hint-md',
            lg: 'text-field-hint-lg',
            xl: 'text-field-hint-xl',
        },
    },
    defaultVariants: {
        state: 'default',
        size: 'md',
    },
});
export const label = cva('block', {
    variants: {
        disabled: {
            true: 'text-text-field-disabled',
            false: 'text-text-field-label-text',
        },
        size: {
            sm: 'text-field-label-sm',
            md: 'text-field-label-md',
            lg: 'text-field-label-lg',
            xl: 'text-field-label-xl',
        },
    },
    defaultVariants: {
        disabled: false,
        size: 'md',
    },
});
export const styles = {
    formControl,
    root,
    input,
    icon,
    hint,
    optional,
    label,
};
