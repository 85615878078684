import { AlignLeft2Line, BarchartFilled, CalendarDatesFilled, Comment2QuestionFilled, Dashboard2Filled, DiscordBrand, EmailFilled, ExternalLinkLine, FileUploadFilled, GearFilled, Link2Filled, QuestionLine, SortNumberLine, TelegramBrand, TiktokBrand, TwitterFilled, WalletConnectBrand, } from '@zealy/icons';
export const TASK_ICONS = {
    file: FileUploadFilled,
    image: FileUploadFilled,
    discord: DiscordBrand,
    telegram: TelegramBrand,
    twitter: TwitterFilled,
    tweet: TwitterFilled,
    tweetReact: TwitterFilled,
    twitterSpace: TwitterFilled,
    twitterFollow: TwitterFilled,
    poll: BarchartFilled,
    quiz: Comment2QuestionFilled,
    opinion: Dashboard2Filled,
    text: AlignLeft2Line,
    date: CalendarDatesFilled,
    number: SortNumberLine,
    url: Link2Filled,
    visitLink: ExternalLinkLine,
    invites: EmailFilled,
    api: GearFilled,
    default: QuestionLine,
    wallet: WalletConnectBrand,
    tiktok: TiktokBrand,
};
