export const TASK_COLORS = {
    file: '--color-bg-task-type-file-upload',
    image: '--color-bg-task-type-file-upload',
    discord: '--color-bg-task-type-discord-default',
    telegram: '--color-bg-task-type-telegram-default',
    twitter: '--color-bg-task-type-twitter-default',
    tweet: '--color-bg-task-type-twitter-default',
    tweetReact: '--color-bg-task-type-twitter-default',
    twitterSpace: '--color-bg-task-type-twitter-default',
    twitterFollow: '--color-bg-task-type-twitter-default',
    poll: '--color-bg-task-type-poll',
    quiz: '--color-bg-task-type-quiz',
    opinion: '--color-bg-task-type-opinion-scale',
    text: '--color-bg-task-type-text',
    date: '--color-bg-task-type-date',
    number: '--color-bg-task-type-number',
    url: '--color-bg-task-type-url',
    visitLink: '--color-bg-task-type-visit-link',
    invites: '--color-bg-task-type-invites',
    api: '--color-bg-task-type-api',
    wallet: '--color-bg-task-type-twitter-default',
    tiktok: '--color-bg-task-type-tiktok-default',
    partnership: '--color-bg-task-type-partnership-default',
    nft: '--color-bg-task-type-on-chain',
    token: '--color-bg-task-type-on-chain',
    onChain: '--color-bg-task-type-on-chain',
};
