import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { cn } from '../../utils/cn';
import { getQuestCSSVariable } from '../../utils/getQuestCSSVariable';
import { TASK_ICONS } from './Task.const';
import { containerStyle, contentStyle, header, wrapper } from './TaskCard.styles';
const DEFAULT_COLOR = 'var(--color-bg-brand-primary-default)';
const DEFAULT_BORDER_COLOR = 'var(--color-border-component-tertiary-default)';
export const TaskCard = ({ children, title, isDashed, className, color = DEFAULT_COLOR, borderColor = DEFAULT_BORDER_COLOR, type, 
// TODO: do the same way as the quest card so we don't need bg
bgColor = 'var(--color-bg-secondary)', icon, hint, ...props }) => {
    const questCSSVariable = type ? getQuestCSSVariable(type) : undefined;
    const Icon = icon ?? TASK_ICONS[type] ?? TASK_ICONS.poll;
    const hasTitle = !!title;
    const style = useMemo(() => ({
        '--task-card-color': hasTitle ? `var(${questCSSVariable})` : borderColor,
        '--task-card-border-color': borderColor,
        '--bg': bgColor,
    }), [hasTitle, questCSSVariable, borderColor, bgColor]);
    return (_jsxs("div", { className: cn('relative w-full min-w-0', title ? 'pt-300' : ''), style: style, children: [(title || hint) && (_jsxs("div", { className: "flex flex-row gap-200 justify-between", children: [title && (_jsxs("div", { "data-testid": "taskcard-header", className: header, children: [_jsx(Icon, { size: 12, color: "white" }), _jsx("span", { className: "label-md text-white", children: title })] })), hint && _jsx("div", { className: "absolute right-0 top-0", children: hint })] })), _jsxs("div", { className: wrapper, children: [_jsx("div", { className: cn(contentStyle, className), ...props, children: children }), _jsx("div", { className: containerStyle({ isDashed }) })] })] }));
};
