import { cva, cx } from 'class-variance-authority';
export const header = cx('px-100 pt-50 rounded-t-component-sm flex items-center gap-50 pb-400 absolute top-0 z-0 bg-[var(--task-card-color)] pointer-events-none');
export const wrapper = cx('rounded-md flex relative overflow-visible', 'before:rounded-md before:z-0 before:absolute before:bg-gradient-to-br before:p-12 before:inset-0 before:from-[var(--task-card-color)] before:from-0% before:via-[var(--task-card-color)] before:via-26% before:to-[var(--task-card-border-color)] before:to-40%');
export const contentStyle = 'w-full min-w-0 z-[1]';
export const containerStyle = cva('absolute left-[1px] top-[1px] right-[1px] bottom-[1px] rounded-md overflow-hidden p-component-lg border-image min-w-0 flex flex-col gap-300 bg-[var(--bg)]', {
    variants: {
        isDashed: {
            true: 'outline-dashed outline-[1.5px] outline-[var(--bg)]',
        },
    },
});
