import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, forwardRef } from 'react';
import { cn } from '../../utils/cn';
import { FeedbackIcon, getComponentState } from '../../utils/getComponentState';
import { FormControl } from '../FormControl';
import { styles } from './TextField.styles';
export const Icon = ({ className, size, state, icon, ...props }) => icon
    ? cloneElement(icon, {
        className: cn(styles.icon({
            size,
            state,
        }), icon.props.className, className),
        ...props,
    })
    : null;
export const Input = forwardRef(function InputField(props, ref) {
    const { leftIcon, name, isInvalid, isDisabled, isSuccess, size, className, rightIcon, ...rest } = props;
    const state = getComponentState({ isDisabled, isInvalid, isSuccess });
    const StatusIcon = FeedbackIcon[state];
    return (_jsxs("div", { className: cn(styles.root({
            size,
            state,
        }), className), children: [_jsx(Icon, { icon: leftIcon, size: size }), _jsx("input", { id: name, name: name, disabled: isDisabled, ref: ref, className: styles.input({
                    size,
                    state,
                }), ...rest }), _jsx(Icon, { icon: rightIcon, size: size }), StatusIcon && (_jsx(StatusIcon, { "aria-label": state, className: cn(styles.icon({
                    size,
                    state,
                })) }))] }));
});
export const TextField = forwardRef(function TextField(props, ref) {
    const { label, name, isInvalid, isDisabled, isSuccess, size, hint, optional, ...rest } = props;
    const state = getComponentState({ isDisabled, isInvalid, isSuccess });
    return (_jsx(FormControl, { label,
        name,
        state,
        hint,
        optional,
        size, children: _jsx(Input, { name, isInvalid, isDisabled, isSuccess, size, ...rest, ref: ref }) }));
});
